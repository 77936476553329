import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  getSanctumUser,
  sanctumCsrf,
  signIn,
  logout as userLogout,
  signInWithGoogleCallback,
} from "@/services/api/User";
import toast from "react-hot-toast";
import { getCookie, deleteCookie, setCookie } from 'cookies-next';
import { env } from "@/next.config";
import { getStartupDetailSimple, updateStartup, createStartupByUser } from "@/services/api/Startup";
import checkEmailDomain from "@/utils/checkEmailDomain";
import { updateHeaders } from "@/utils/httpUtils";
import { useAtom } from "jotai";
import { loginPopup, purchasePopup, userAtom } from "@/Atoms";

const useAuth = function ({ middleware, redirectIfAuthenticated } = {}) {
  const router = useRouter();
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchasePopup);
  const [userState, setUserState] = useAtom(userAtom);
  const [loginError, setLoginError] = useState("");

  const [isLoading, setLoading] = useState(false);
  redirectIfAuthenticated =
    redirectIfAuthenticated === null || redirectIfAuthenticated === undefined
      ? true
      : redirectIfAuthenticated;

  const {
    data: user,
    error,
    mutate,
  } = useSWR("/user", () => getSanctumUser().then((response) => response.data));

  const csrf = () => sanctumCsrf();

  const login = async ({ setErrors, startup, purpose, notRedirect, ...props }) => {
    setErrors([]);
    setLoading(true);

    let redirect = ''

    await csrf();

    try {
      const response = await signIn(props);

      mutate();
      const { user } = response.data;
      user.roles = user?.roles === 'admin' ? 'admin-team' : user?.roles
      setUserState(user)

      if (startup) {
        const access_token = response.data.access_token;
        updateHeaders({
          "Authorization": `Bearer ${access_token}`
        });

        if (purpose === 'claim' && startup.id) {
          const payload = {
            name: startup.name,
            website: startup.website,
            twitter_url: startup.twitter_url,
            linkedin_url: startup.linkedin_url,
            logo_url: startup.logo_url,
            country_id: startup.country_id,
            creator_id: user.id,
            summary: startup.summary,
            claimed: 1,
            owner_id: user.id,
            owner_email: checkEmailDomain(user.email, startup.website) ? user.email : officialEmail,
          };
          
          const responseClaim = await updateStartup(startup.id, payload);
          localStorage.setItem('startup-event', 'claim')
        }

        if (purpose === 'register') {
          const payload = {
            name: startup.name,
            website: startup.website,
            owner_id: user.id,
            owner_email: checkEmailDomain(user.email, startup.website) ? user.email : officialEmail,
            creator_id: user.id
          };
          
          const responseRegister = await createStartupByUser(payload);
          localStorage.setItem('startup-event', 'register')
          redirect = `/startups/${responseRegister.data.data.slug}-${responseRegister.data.data.id}`
        }
      }

      
      if (process.env.ENV == 'local') {
        setCookie('dev-token', response.data?.access_token)
      }

      if (!notRedirect) {
        // if (user.last_login_at) {
          if (redirect) {
            location.href = redirect
          } else if (router.query.redirect_to) {
            // router.push(router.query.redirect_to);
            location.href = router.query.redirect_to
          } else {
            setOpenLoginPopup(false)
            if (router.pathname === "/auth/login") {
              location.href = "/"
            } else {
              location.reload()
            }
            // router.push("/");
          }
        // } else {
        //   location.href = `/register/profile-register${router.query.redirect_to ? `?redirect_to=${router.query.redirect_to}` : ''}`
        //   // router.push(`/register/profile-register${router.query.redirect_to ? `?redirect_to=${router.query.redirect_to}` : ''}`);
        // }
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      if (error.response && error.response.data) {
        setLoginError(error.response.data.message)
      }
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    setLoading(true);
    try {
      await sanctumCsrf();
      const response = await signInWithGoogleCallback(router.query);
      mutate();

      const { user } = response.data;
      user.roles = user?.roles === 'admin' ? 'admin-team' : user?.roles
      setUserState(user);

      // if (user.last_login_at) {
        if (getCookie('google-url-redirect-to')) {
          const url = getCookie('google-url-redirect-to')
          deleteCookie('google-url-redirect-to')
          // router.push(url);
          location.href = url
        } else {
          // router.push("/");
          location.href = "/"
        }
      // } else {
      //   // router.push("/register/profile-register");
      //   location.href = "/register/profile-register"
      // }
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    if (process.env.ENV == 'local') {
      deleteCookie('dev-token')
    }
    
    await userLogout();
    
    toast.success("You've logged out!");
    // router.push("/");
    location.href = "/"
    // mutate(null);
  };

  useEffect(() => {
    if (user || error) {
      setLoading(false);
    }

    if (middleware == "guest" && user && redirectIfAuthenticated)
      router.push("/");
    if (middleware == "auth" && !user && error)
      router.push(`/auth/login?redirect_to=${router.asPath}`);
  }, [user, error]);

  if (user) {
    user.roles = user?.roles === 'admin' ? 'admin-team' : user?.roles
  }

  setUserState(user)

  return {
    user,
    csrf,
    login,
    logout,
    isLoading,
    signInWithGoogle,
    loginError,
    setLoginError
  };
};

export default useAuth;
