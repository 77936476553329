import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export function sanctumCsrf() {
  return httpUtils.get("/api/csrf-cookie", {
    headers: { 
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    credentials: 'include',
  });
}

export function register(payload) {
  return httpUtils.post(`${env.API_VERSION}/register`, payload);
}

export function registerDirect(payload) {
  return httpUtils.post(`${env.API_VERSION}/register-direct`, payload);
}

export function signIn(credentials) {
  return httpUtils.post(`${env.API_VERSION}/login`, credentials);
}

export function getProfile() {
  return httpUtils.get(`${env.API_VERSION}/user/profile`);
}

export function getSanctumUser() {
  return httpUtils.get("/api/user");
}

export function saveUserProfile(payload) {
  return httpUtils.post(`${env.API_VERSION}/user/update-profile`, payload);
}

export function logout() {
  return httpUtils.post(`${env.API_VERSION}/logout`);
}

export function getUserContentPreferences() {
  return httpUtils.get(`${env.API_VERSION}/user/content-preferences`);
}

export function getUserPreferences() {
  return httpUtils.get(`${env.API_VERSION}/user/preferences`);
}

export function updateUserPreferences(payload) {
  return httpUtils.post(`${env.API_VERSION}/user/save-preferences`, payload);
}

export function updateUserContentPreferences(payload) {
  return httpUtils.post(`${env.API_VERSION}/user/content-preferences`, payload);
}

export function getDownloadHistory(params) {
  return httpUtils.get(`${env.API_VERSION}/user/download-history`, { params });
}

export function fetchGoogleAuthDetail() {
  return httpUtils.get(`${env.API_VERSION}/auth/google`);
}

export function signInWithGoogleCallback(params) {
  return httpUtils.get(`${env.API_VERSION}/auth-callback/google`, { params });
}

export function getReportHistory() {
  return httpUtils.get(`${env.API_VERSION}/user/report-history`);
}

export function sendDontSellRequest(payload) {
  return httpUtils.post(`${env.API_VERSION}/dont-sell`, payload);
}

export function forgotPassword(payload) {
  return httpUtils.post(`${env.API_VERSION}/auth/forgot-password`, payload);
}

export function resetPassword(token, payload) {
  return httpUtils.patch(`${env.API_VERSION}/auth/reset-password/${token}`, payload);
}

export function checkUserSubscription() {
  return httpUtils.get(`${env.API_VERSION}/check-user-subscription`);
}

export function checkCoupon(id) {
  return httpUtils.get(`${env.API_VERSION}/check-coupon/${id}`);
}

export function userDetail(id) {
  return httpUtils.get(`${env.API_VERSION}/users/${id}`);
}

export function createAbandonedCart(payload) {
  return httpUtils.post(`${env.API_VERSION}/abandoned-carts`, payload);
}

export function updatePassword(payload) {
  return httpUtils.post(`${env.API_VERSION}/change-password`, payload);
}

