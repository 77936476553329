import emailList from "../official_email_list.json"

function checkEmailDomain(email, domain) {
    if (!email) return false
  let emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return false;
  }

  let emailDomain = emailParts[1];

  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomainParts = urlDomain.split(".").slice(-2);
  let baseDomain = baseDomainParts.join(".");

  return emailDomain === baseDomain;
}

export function getBaseDomain(domain) {
  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomainParts = urlDomain.split(".").slice(-2);
  let baseDomain = baseDomainParts.join(".");

  return baseDomain;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidDomain(domain) {
  var domainReg = /^(https?:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,6}(\/|$)/;
  return domainReg.test(domain);
}

function findVerificationEmail(domain) {
  for (let entry of emailList) {
    let entryDomain;
    try {
      if (!/^http(s)?:\/\//.test(entry.Website)) {
        entry.Website = "https://" + entry.Website;
      }
      entryDomain = new URL(entry.Website).hostname;
    } catch (_) {
      continue;
    }

    let baseDomainParts = entryDomain.split(".").slice(-2);
    let baseDomain = baseDomainParts.join(".");

    if (baseDomain === domain) {
      return entry["Verification Email"];
    }
  }
  return null;
}

export function checkEmailDomainWithList(email, domain) {
  if (!email) return false;
  let emailParts = email.split("@");

  if (emailParts.length !== 2) {
    return false;
  }

  let emailDomain = emailParts[1];

  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomainParts = urlDomain.split(".").slice(-2);
  let baseDomain = baseDomainParts.join(".");

  // Find the verification email domain
  let verificationEmail = findVerificationEmail(baseDomain);

  return emailDomain === (verificationEmail || baseDomain);
}

export function getBaseDomainWithList(domain) {
  let urlDomain;
  try {
    if (!/^http(s)?:\/\//.test(domain)) {
      domain = "https://" + domain;
    }
    urlDomain = new URL(domain).hostname;
  } catch (_) {
    return false;
  }

  // Get the base domain from the urlDomain
  let baseDomainParts = urlDomain.split(".").slice(-2);
  let baseDomain = baseDomainParts.join(".");

  // Find the verification email domain
  let verificationEmail = findVerificationEmail(baseDomain);

  return verificationEmail || baseDomain;
}

export default checkEmailDomain;
